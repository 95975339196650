<template>
  <div class="page-title--mobile" v-if="isMobile">
    <a href="javascript:void(0);" @click="filterBtnClick" v-if="showFilterBtn">
      <span class="icon q-mr-sm">
        <q-icon name="filter_list" size="sm" />
        <span class="count" v-show="filterAplliedCount">
          {{ filterAplliedCount }}
        </span>
      </span>
      {{ filterBtnName }}
    </a>
    <a href="javascript:void(0);" @click="sortBtnClick" v-if="showSortBtn">
      <span class="icon q-mr-sm">
        <q-icon name="sort_by_alpha" size="sm" />
      </span>
      {{ sortBtnName }}
    </a>
    <a
      href="javascript:void(0);"
      v-if="showDiscountButton"
      @click="
        changeDialogState({
          dialog: 'ExploreDiscount',
          val: true,
        })
      "
    >
      <span class="icon q-mr-sm">
        <q-icon name="filter_list" size="sm" />
      </span>
      Discounts
    </a>
    <!-- <div
      v-if="
        showPagination &&
        Math.ceil(this.pagination.totalCount / this.pagination.pageSize) > 1
      "
      class="mobile-pagination"
    >
      <q-pagination
        v-model="currentPage"
        color="secondary"
        :max="Math.ceil(this.pagination.totalCount / this.pagination.pageSize)"
        :max-pages="3"
        boundary-numbers
        direction-links
        @input="$emit('product-list-pagination', currentPage)"
      />
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'FilterButtons',
  props: {
    showFilterBtn: {
      type: Boolean,
      default: true,
    },
    showSortBtn: {
      type: Boolean,
      default: true,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    showDiscountButton: {
      type: Boolean,
      default: false,
    },
    filterBtnName: {
      type: String,
      default: 'Filter',
    },
    sortBtnName: {
      type: String,
      default: 'Sort',
    },
    filterBtnClick: {
      type: Function,
      default: () => {},
    },
    sortBtnClick: {
      type: Function,
      default: () => {},
    },
    filterAplliedCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    currentPage: {
      get() {
        return this.pagination.currentPage
      },
      set(val) {
        if (val != this.currentPage)
          this.$store.commit('product/UPDATE_PAGINATION', {
            ...this.pagination,
            currentPage: val,
          })
      },
    },
    ...mapGetters('product', ['pagination']),
  },
}
</script>

<style lang="scss">
@media (max-width: 1023px) {
  .q-page {
    padding-bottom: 60px;
    &.q-pb-none {
      padding-bottom: 0;
    }
  }
}
.page-title--mobile {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 99;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  border-top: 1px solid rgba(136, 136, 136, 0.2);
  margin-top: 20px;
  // align-items: center;
  align-items: stretch;
  font-size: 14px;
  @media (min-width: 1025px) {
    display: none;
  }
  a {
    padding: 15px 5px;
    color: #888888;
    text-decoration: none;
    flex: 1 0 25%;
    text-align: center;
    border-left: 1px solid rgba(136, 136, 136, 0.2);
    &:first-child {
      border: none;
    }
  }
  .icon {
    position: relative;
  }
  .count {
    position: absolute;
    right: -2px;
    top: -2px;
    display: block;
    font-size: 7px;
    background: #f32626;
    color: #fff;
    width: 12px;
    height: 12px;
    line-height: 12px;
    text-align: center;
    border-radius: 50%;
    box-shadow: none;
  }
  // .filter-pagination {
  //   flex: 1 0 75%;
  // }
}
.mobile-pagination {
  display: flex;
  border-left: 1px solid #ededed;
  .q-btn {
    border-radius: 100%;
    min-width: 27px !important;
    min-height: 27px;
    font-size: 16px;
    line-height: 1;
    &.disabled {
      visibility: hidden;
    }
  }
}
</style>
