import { render, staticRenderFns } from "./FilterButtons.vue?vue&type=template&id=0693cc3e"
import script from "./FilterButtons.vue?vue&type=script&lang=js"
export * from "./FilterButtons.vue?vue&type=script&lang=js"
import style0 from "./FilterButtons.vue?vue&type=style&index=0&id=0693cc3e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QIcon,QPagination,QPage,QBtn});
